import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './icon.module.scss';

const Icon = ({ text, activeImage, inactiveImage, alt, isActive, hasTabs }) => (
	<>
		<div className={classNames(styles.textArrowContainer, isActive && styles.active)}>
			<span className={styles.activeText}>You are here</span>
			<div className={styles.arrow} />
		</div>
		<img className={styles.img} src={isActive ? activeImage : inactiveImage} alt={alt} />
		<h6 className={classNames(styles.text, hasTabs && styles.hasTabs)}>{text}</h6>
	</>
);

Icon.propTypes = {
	text: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	activeImage: PropTypes.string.isRequired,
	inactiveImage: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	hasTabs: PropTypes.bool
};

Icon.defaultProps = {
	isActive: false,
	hasTabs: false
};

export default Icon;
