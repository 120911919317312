import React from 'react';
import PropTypes from 'prop-types';
import styles from './frame.module.scss';
import { classNames } from '@utils';

const presets = {
	wide: 16 / 9,
	landscape: 4 / 3
};

const Frame = props => {
	const { style, color, dataRounded } = props;
	const ratio = props.ratio || presets[props.preset];

	let ratioStyles;
	if (ratio) {
		ratioStyles = {
			paddingBottom: `${100 / props.ratio}%`
		};
	}

	return (
		<div
			data-rounded={dataRounded}
			color={color}
			style={style}
			className={classNames(
				styles.frame,
				props.className,
				props.cover && styles.cover,
				props.contain && styles.contain
			)}
			data-ratio={props.ratio}
		>
			<div className={styles.frameMedia} style={ratioStyles}>
				{props.children}
			</div>
		</div>
	);
};

Frame.propTypes = {
	preset: PropTypes.oneOf(Object.keys(presets)),
	ratio: PropTypes.number,
	cover: PropTypes.bool,
	dataRounded: PropTypes.string
};

export default Frame;
