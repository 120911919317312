import React from 'react';
import PropTypes from 'prop-types';
import styles from './intro.module.scss';
import Container from '@components/container';
import SectionDescription from '@components/section-description';
import { Breakpoints, classNames } from '@utils';
import Media from '@components/media';

const Intro = props => {
	const {
		illustrations,
		secondChild,
		isFullContainer,
		is8ColumnsWidthTitle,
		is8ColumnsWidthDescription,
		isFullWidthDescription,
		hasWhiteBackground,
		h1Title,
		h2Title,
		h3Title,
		imageAlt,
		theme
	} = props;

	return (
		<section
			className={classNames(
				theme && theme.section,
				styles.section,
				illustrations && styles.sectionWithMedia,
				hasWhiteBackground && styles.hasWhiteBackground,
				!props.description && !props['description-2'] && styles.withoutDescription
			)}
		>
			<Container small={!illustrations && !isFullContainer} className={illustrations && styles.withMedia}>
				<div className={styles.row}>
					<div className={classNames(styles.column, is8ColumnsWidthTitle && styles.is8ColumnsWidth)}>
						<SectionDescription
							sectionTitle={props.sectionTitle}
							title={props.title}
							h1Title={h1Title}
							h2Title={h2Title}
							h3Title={h3Title}
						/>
					</div>
				</div>
				<div className={classNames(styles.row, illustrations && styles.leftColumn)}>
					<div
						className={classNames(
							styles.column,
							is8ColumnsWidthDescription && styles.is8ColumnsWidth,
							isFullWidthDescription && styles.isFullWidthColumn
						)}
					>
						<SectionDescription description={props.description} />
					</div>
					{!!props['description-2'] && (
						<div
							className={classNames(
								styles.column,
								is8ColumnsWidthDescription && styles.is8ColumnsWidth,
								isFullWidthDescription && styles.isFullWidthColumn,
								illustrations && styles.oneColumnText
							)}
						>
							<SectionDescription secondChild={secondChild} description={props['description-2']} />
						</div>
					)}
				</div>
				{!!illustrations && illustrations.length > 0 && (
					<div className={styles.media}>
						<div className={classNames(illustrations.length > 1 && styles.fadeInOutImages)}>
							<Breakpoints>
								{breakpoint => {
									const rounded = {
										md: true,
										lg: true,
										xl: true
									};

									const dataRounded = {
										lg: 'left',
										xl: 'left'
									};

									return illustrations.map((illustration, i) => (
										<Media
											key={i}
											image={illustration}
											ratio={1920 / 1174}
											shadow
											rounded={rounded[breakpoint]}
											dataRounded={dataRounded[breakpoint]}
											alt={imageAlt}
										/>
									));
								}}
							</Breakpoints>
						</div>
					</div>
				)}
			</Container>
		</section>
	);
};

Intro.defaultProps = {
	isFullContainer: false,
	hasWhiteBackground: false,
	is8ColumnsWidthTitle: false,
	is8ColumnsWidthDescription: false,
	isFullWidthDescription: false
};

Intro.propTypes = {
	illustrations: PropTypes.array,
	secondChild: PropTypes.bool,
	isFullContainer: PropTypes.bool,
	is8ColumnsWidthTitle: PropTypes.bool,
	is8ColumnsWidthDescription: PropTypes.bool,
	isFullWidthDescription: PropTypes.bool,
	hasWhiteBackground: PropTypes.bool,
	h1Title: PropTypes.bool,
	h2Title: PropTypes.bool,
	h3Title: PropTypes.bool,
	imageAlt: PropTypes.string,
	theme: PropTypes.object
};

export default Intro;
