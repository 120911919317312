import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styles from './tab.module.scss';
import { classNames } from '@utils';

const Tab = ({ id, isActive, onSelectedTab, text }) => {
	const handleClick = useCallback(() => {
		if (!isActive) {
			onSelectedTab(id);
		}
	});

	return (
		<div className={classNames(styles.tab, isActive && styles.isActive)} onClick={handleClick}>
			{text}
		</div>
	);
};

Tab.propTypes = {
	id: PropTypes.number.isRequired,
	isActive: PropTypes.bool.isRequired,
	onSelectedTab: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired
};

export default Tab;
