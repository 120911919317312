import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from '@components';
import classNames from 'classnames';
import Container from '@components/container';
import styles from './product-features-grid.module.scss';

const ProductFeaturesGrid = ({ items }) => (
	<Container small>
		<div className={styles.container}>
			{items.map(({ title, description }, i) => {
				const tag = (i + 1).toString().padStart(2, '0');
				const isEven = i % 2 === 0;
				return (
					<div key={i}>
						<div className={classNames(styles.tag, isEven ? styles.leftItem : styles.rightItem)}>{tag}</div>
						<div className={styles.line} />
						<div className={classNames(isEven ? styles.leftItem : styles.rightItem)}>
							{title && <h3 className={styles.title}>{title}</h3>}
							{description && (
								<div className={styles.description}>
									<ReactMarkdown children={description} />
								</div>
							)}
						</div>
					</div>
				);
			})}
		</div>
	</Container>
);

ProductFeaturesGrid.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			description: PropTypes.string
		})
	).isRequired
};

export default ProductFeaturesGrid;
