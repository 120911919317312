import React from 'react';
import PropTypes from 'prop-types';
import styles from './media.module.scss';
import Frame from '@components/frame';
import Image from '@components/image';
import { classNames, withModernizr } from '@utils';

const ImageFrame = props => {
	const { alt, image, children, ...frameProps } = props;
	return (
		<Frame {...frameProps}>
			<Image src={image} alt={alt} />
			{children}
		</Frame>
	);
};

const Media = withModernizr(props => {
	const { objectfit } = props.Modernizr;
	const simpleShadow = props.shadow && !objectfit;
	return (
		<div
			className={classNames(
				props.className,
				styles.media,
				props.cover && styles.cover,
				props.contain && styles.contain
			)}
		>
			{props.shadow && objectfit && (
				<ImageFrame
					{...props}
					data-rounded={props.dataRounded}
					className={classNames(styles.shadow, props.rounded && styles.rounded)}
				/>
			)}
			<ImageFrame
				{...props}
				dataRounded={props.dataRounded}
				className={classNames(
					styles.content,
					props.rounded && styles.rounded,
					props.isBackground && styles.contentBackground,
					simpleShadow && styles.simpleShadow
				)}
			>
				{props.children && <div className={styles.children}>{props.children}</div>}
			</ImageFrame>
		</div>
	);
});

Media.propTypes = {
	shadow: PropTypes.bool,
	rounded: PropTypes.bool,
	dataRounded: PropTypes.string,
	image: PropTypes.string,
	alt: PropTypes.string,
	...Frame.propTypes
};

export default Media;
