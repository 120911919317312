import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from '@components';

import Button from '@components/button';
import Container from '@components/container';
import Icon from '@components/icon';
import SectionDescription from '@components/section-description';
import styles from './title-with-checklist.module.scss';

const TitleWithChecklist = ({ title, description, list, button }) => (
	<Container className={styles.container} small>
		{title && <SectionDescription title={title} h1Title />}
		{description && <SectionDescription className={styles.description} description={description} />}
		<div className={styles.list}>
			{list.map(({ text }, i) => (
				<div key={i} className={styles.item}>
					<Icon className={styles.bullet} name="check" />
					<div>
						<ReactMarkdown key={i} children={text} />
					</div>
				</div>
			))}
		</div>
		{button && (
			<Button className={styles.button} title={button.title} link={button.link}>
				{button.title}
			</Button>
		)}
	</Container>
);
TitleWithChecklist.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	).isRequired,
	button: PropTypes.shape({
		title: PropTypes.string.isRequired,
		link: PropTypes.string.isRequired
	})
};

TitleWithChecklist.defaultProps = {
	title: null,
	button: null
};

export default TitleWithChecklist;
