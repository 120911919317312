import React from 'react';
import PropTypes from 'prop-types';
import { withModernizr } from '@utils';


const Image = withModernizr(props => {

	return <img data-src={props.src} alt={props.alt} className={props.className + ' lazyload'}/>;
});

Image.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	className: PropTypes.string
};

export default Image;
