import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import Link from '@components/link';
import Icon from './icon';
import Tab from './tab';
import styles from './you-are-here-icon-tab.module.scss';

const YouAreHereIconTab = ({ tabs, linkTo, ...otherProps }) => {
	const [isActive, setIsActive] = useState(false);
	const [activeTab, setActiveTab] = useState(0);

	const handleClickTab = useCallback(tabId => {
		navigate(`${tabs[tabId].linkTo}`);
	});

	useEffect(() => {
		let isActiveIcon = location.pathname.includes(linkTo);
		if (tabs) {
			tabs.forEach((tab, i) => {
				if (location.pathname.includes(tab.linkTo)) {
					setActiveTab(i);
					if (!isActiveIcon) {
						isActiveIcon = location.pathname.includes(tab.linkTo);
					}
				}
			});
		}
		setIsActive(isActiveIcon);
	}, []);

	return tabs && tabs.length > 0 ? (
		<div className={styles.container}>
			<Icon linkTo={linkTo} isActive={isActive} hasTabs {...otherProps} />
			<div className={styles.tabs}>
				{tabs.map((tab, i) => (
					<Link key={i} id={tab.linkTo} to={tab.linkTo}>
						<Tab
							key={i}
							id={i}
							isActive={activeTab === i}
							text={tab.title}
							onSelectedTab={handleClickTab}
						/>
					</Link>
				))}
			</div>
		</div>
	) : (
		<Link id={linkTo} to={linkTo} className={styles.link}>
			<Icon linkTo={linkTo} isActive={isActive} {...otherProps} />
			<div className={classNames(styles.tabs, styles.empty)} />
		</Link>
	);
};

YouAreHereIconTab.propTypes = {
	linkTo: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	activeImage: PropTypes.string.isRequired,
	inactiveImage: PropTypes.string.isRequired,
	tabs: PropTypes.array
};

YouAreHereIconTab.defaultProps = {
	tabs: null
};

export default YouAreHereIconTab;
