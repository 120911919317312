import React from 'react';
import PropTypes from 'prop-types';

import Container from '@components/container';
import SectionDescription from '@components/section-description';
import styles from './offering-intro.module.scss';

const OfferingIntro = ({ title, description }) => {
	return (
		<Container className={styles.container} small>
			<SectionDescription title={title} h1Title />
			<h3 className={styles.description}>{description}</h3>
		</Container>
	);
};

OfferingIntro.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
};

OfferingIntro.defaultProps = {
	title: null,
	description: null
};

export default OfferingIntro;
