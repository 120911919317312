import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from '@components/container';
import InfiniteCarousel from '@components/infinite-carousel';
import SectionDescription from '@components/section-description';
import Picture from '@components/picture';
import styles from './tools.module.scss';

const Framework = (props) => {
	const { image, isSmall, name, showName } = props;
	return !!image ? (
		<div className={classNames(styles.slide, isSmall && styles.small)}>
			<Picture sources={image} alt={name} />
			{!!showName && <h3 className={styles.name}>{name}</h3>}
		</div>
	) : null;
};

Framework.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string
};

const getRows = (rows, array) => {
	const items = [];
	while (rows) {
		array.forEach((e) => {
			items.push(e);
		});
		rows--;
	}

	return items;
};

const Tools = (props) => {
	const { className, title, showItemsName, isSmall, items } = props;
	const slides = getRows(4, items);

	return !!items && items.length ? (
		<section className={classNames(styles.tools, className)}>
			<Container small>
				{title && <SectionDescription title={title} />}
				<div className={classNames(styles.carouselContainer, isSmall && styles.small)}>
					<InfiniteCarousel items={slides} Component={Framework} showName={showItemsName} isSmall={isSmall} />
				</div>
			</Container>
		</section>
	) : null;
};

Tools.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	items: PropTypes.array.isRequired
};

Tools.defaultProps = {
	className: null,
	title: null
};

export default Tools;
