import React from 'react';
import YouAreHereIconTab from './you-are-here-icon-tab';
import styles from './you-are-here.module.scss';

const items = [
	{
		linkTo: '/product-strategy',
		text: 'Product Strategy',
		alt: 'Prod Strategy',
		activeImage: '/cms/assets/prod-strategy-icon-active.svg',
		inactiveImage: '/cms/assets/prod-strategy-icon.svg'
	},
	{
		linkTo: '/product-design',
		text: 'Product Design',
		alt: 'Prod Design',
		activeImage: '/cms/assets/prod-design-icon-active.svg',
		inactiveImage: '/cms/assets/prod-design-icon.svg'
	},
	{
		linkTo: '/nearshore-web-app-development',
		text: 'Product Development',
		alt: 'Prod Development',
		activeImage: '/cms/assets/prod-development-icon-active.svg',
		inactiveImage: '/cms/assets/prod-development-icon.svg',
		tabs: [
			{
				title: 'Web',
				linkTo: '/nearshore-web-app-development'
			},
			{
				title: 'Mobile',
				linkTo: '/outsourcing-mobile-development'
			}
		]
	},
	{
		// TODO: Update linkTo
		linkTo: '/product-growth',
		text: 'Product Growth',
		alt: 'Prod Growth',
		activeImage: '/cms/assets/prod-growth-icon-active.svg',
		inactiveImage: '/cms/assets/prod-growth-icon.svg'
	}
];

const YouAreHere = () => {
	return (
		<div className={styles.container}>
			{items.map((item, i) => (
				<YouAreHereIconTab key={i} {...item} />
			))}
			<span className={styles.line}></span>
		</div>
	);
};

export default YouAreHere;
